body {
	background: #fff !important;
	/* color: #d0d2d6; */
	font-size: 14px !important;
	font-family: "Poppins", sans-serif;
}

a {
	text-decoration: none !important;
}

.progress-bar {
	overflow: visible;
	color: #181c28;
}

#editdiv {
	position: absolute;
	width: 280px;
	background: #000;
	color: #FFF;
	padding: 5px;
	border-radius: 10px;
}

.nav-tabs .nav-link  {
	color: #e38524;
	font-size: large;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
	color: #000;
	background-color: #fff;
}

.custom_table * {
	color: #fff !important;
	background-color: #181c28 !important;
}

.custom_table_select {
	--bs-table-color: #fff;
	--bs-table-bg: #181c28;
	--bs-table-striped-bg: #323539;
	--bs-table-striped-color: #fff;
	--bs-table-active-bg: #323539;
	--bs-table-active-color: #fff;
	--bs-table-hover-bg: #15192a;
	--bs-table-hover-color: #fff;
	color: var(--bs-table-color);
	border-color: var(--bs-table-border-color);
}

.custom_table .dropdown-toggle,
.dropdown-menu,
.dropdown-menu a span,
.dropdown-menu a,
hr {
	background-color: #fff !important;
	color: #000 !important;
}

.custom_form .form-label {
	display: block;
	margin-top: 8px;
	color: #fff;
}

.table-responsive {
	overflow-x: inherit !important;
}

.custom_form .form-group {
	margin-bottom: 20px;
}

.custom_form .form-control:focus,
.form-select:focus {
	border-color: #7367f0;
	-webkit-box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
	box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}

.custom_form .form-control,
.form-select {
	font-size: 12px !important;
	line-height: 2;
}

.admin_btn {
	border: none;
	background: #e38524;
	color: #fff;
	padding: 6px 32px;
	font-size: 13px;
	border-radius: 5px;
}

.admin_btn.only_text {
	background: transparent;
	color: #293e5b;
}

.admin_btn:disabled {
	background: #b6c5d9;
}

.admin_btn.round_btn {
	display: inline-block;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	text-align: center;
	line-height: 30px;
	padding: 0px;
	font-size: 13px;
}

.custom_nav {
	margin-left: 50px;
	height: 5vh;
	padding: 5px 0px;
}

.custom_header {
	background: #fff;
	color: #636363;
	border-radius: 0.428rem;
	border: 1px solid #eee;
	height: 45px;
	line-height: 45px;
	display: block;
	width: 100%;
}

.custom_nav .close_left_menu {
	float: left;
	padding: 0px;
	margin: 0px;
	position: relative;
	top: -7px;
	margin-right: 6px;
	display: none;
}

.top_wrapper.close_left_menu .custom_nav {
	margin-left: 50px;
}

.custom_nav .elm_logo {
	width: 140px;
	float: left;
	margin-right: 46px;
}

.custom_nav .top_searchbar {
	position: relative;
	box-sizing: border-box;
	float: left;
	width: 580px;
	color: #2b2b2b !important;
}

.user_info_header {
	text-align: right;
}

.menu_wrapper .elm_logo_mobile {
	display: none;
}

.user_info_header .user_menu #basic-nav-dropdown {
	width: 30px;
	height: 30px;
	border: none;
	padding: 0px;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	background: #e38524;
	text-align: center;
	font-size: 17px;
	display: inline-block;
	color: #ffffff;
	line-height: 26px;
	top: 3px;
	margin-right: 18px;
}

.user_info_header .user_menu {
	float: right;
}

.user_info_header .user_menu #basic-nav-dropdown::after {
	display: none;
}

.user_info_header .user_menu a.dropdown-item {
	color: #283046;
	text-transform: capitalize;
	font-size: 15px;
	font-weight: 600;
	padding: 2px 13px;
	display: block;
	transition: all 0.5s;
}

.user_info_header .user_menu .dropdown-menu {
	padding: 3px 0px;
}

.user_info_header .user_menu a.dropdown-item svg {
	margin-right: 7px;
	color: #e38524;
	font-size: 21px;
	top: -1px;
	position: relative;
}

.user_info_header .user_menu a.dropdown-item:focus,
.user_info_header .user_menu a.dropdown-item:active {
	background: transparent;
}

.user_info_header .user_menu_list a:hover {
	background: #f9f9f9;
	transition: all 0.5s;
}

.menu_close {
	background: transparent;
	border: none;
	color: #e38524;
	padding: 0px 11px 0px 11px;
	font-size: 32px;
	margin-bottom: 18px;
}

.full_wrapper {
	height: 100% !important;
	background: #283046;
}

.top_wrapper {
	height: 100% !important;
}

.top_wrapper .menu_wrapper {
	background: #283046;
	position: fixed;
	top: 0px;
	height: 100%;
	width: 50px;
}

.top_wrapper.close_left_menu .menu_wrapper {
	width: 50px;
}

.top_wrapper .right_section {
	padding: 0px;
	margin-left: 249px;
	height: 92vh;
	border-radius: 4px;
	overflow: hidden;
	margin-right: 12px;
}

.top_wrapper.close_left_menu .right_section {
	margin-left: 62px;
}

.open_recent_tab {
	background: transparent;
	border: none;
	font-weight: 500;
	padding: 0px 33px 0px 10px;
	margin-left: 10px;
	position: relative;
}

.open_recent_tab::after {
	content: "\f0d7";
	font: normal normal normal 14px/1 FontAwesome;
	position: absolute;
	right: 11px;
	top: 32%;
}

.top_wrapper .right_section .recent_tabs {
	padding: 0px;
	height: 0px;
	overflow: hidden;
	transition: all 0.4s;
}

.recent_tabs.open {
	height: 26px !important;
	transition: all 0.4s;
}

.top_wrapper .right_section .recent_tabs .recent_tab_list {
	display: flex;
	margin: 0px;
	padding: 0px;
	list-style: none;
}

.top_wrapper .right_section .recent_tabs .recent_tab_list li {
	list-style: none;
	display: inline-block;
	margin-right: 25px;
}

.top_wrapper .right_section .recent_tabs .recent_tab_list li:last-child {
	margin-right: 0px;
}

.top_wrapper .right_section .recent_tabs .recent_tab_list li a {
	background-color: rgba(0, 0, 0, 0.05);
	color: #030303;
	display: inline-block;
	padding: 4px 15px;
	border-radius: 50px;
	font-size: 11px;
	text-transform: capitalize;
	border: 1px solid rgba(0, 0, 0, 0.1);
	font-weight: 500;
	position: relative;
	padding-right: 30px;
}

.top_wrapper .right_section .recent_tabs .recent_tab_list li a .close_tabs {
	position: absolute;
	border: none;
	right: 6px;
	top: 3px;
	z-index: 99;
	background: transparent;
}

.top_wrapper .right_section .recent_tabs .recent_tab_list li a.active {
	background-color: #283046;
	color: #ffffff;
}

.top_wrapper .right_section .recent_tabs .recent_tab_list li a.active .close_tabs {
	color: #ffffff;
}

.top_wrapper .right_section .inner_right_section {
	padding: 0px;
	display: block;
	width: 100%;
	position: relative;
	overflow-x: hidden;
	overflow-y: scroll;
	height: 90vh;
}

.top_wrapper .right_section .inner_right_section::-webkit-scrollbar-track {
	-webkit-box-shadow: none;
	border-radius: 10px;
	background-color: transparent;
}

.top_wrapper .right_section .inner_right_section::-webkit-scrollbar {
	width: 6px;
	background-color: transparent;
}

.top_wrapper .right_section .inner_right_section::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: none;
	background-color: #9b9b9b;
}

.top_wrapper .right_section .inner_right_section .inner_right_content {
	background: #181c28;
	padding: 10px;
	border-radius: 0.428rem;
	min-height: 100%;
}

.top_wrapper .right_section .inner_right_section .inner_right_content .search_section {
	background: rgb(84 184 255 / 4%);
	color: #636363;
	border-radius: 0.428rem;
	border: 0px solid transparent;
	padding: 0px 15px 0px 15px;
	height: 0px;
	overflow: hidden;
	transition: all 0.4s;
}

.top_wrapper .right_section .inner_right_section .inner_right_content .search_section.open_search {
	height: auto;
	padding: 15px 15px 15px 15px;
	border: 1px solid #f3f3f3;
	transition: all 0.4s;
	margin-bottom: 20px;
}

.main_menu {
	padding: 0px;
	padding: 13px 0px;
	list-style: none;
	background: #455070;
	position: absolute;
	z-index: 99;
	width: 50px;
	height: 100vh;
	transition: all 0.4s;
	box-shadow: 7px 0px 18px rgb(3 3 6 / 15%);
	padding-top: 9px;
}

.main_menu .top_menu_list {
	display: block;
	width: 100%;
	color: #d0d2d6;
	font-size: 17px;
	text-align: center;
	width: 40px;
	height: 40px;
	line-height: 41px;
	margin: auto;
	margin-bottom: 8px;
	border-radius: 50px;
	cursor: pointer;
}

.main_menu .top_menu_list.active {
	background: #dcdcdc;
	color: #e38524;
	font-weight: 600;
}

.main_menu .top_menu_list span {
	margin-left: 12px;
}

.menu_dropdown {
	margin: 13px;
	margin-right: 13px;
	margin-left: 13px;
	padding: 0px;
	padding-right: 0px;
	list-style: none;
	margin-left: 51px;
	position: absolute;
	display: block;
	width: 198px;
	top: 60px;
	height: 100%;
	overflow: hidden;
	margin-right: 0px;
	padding-right: 0px;
	background: #2a2f45;
	left: -1px;
	padding: 5px;
	margin-top: 12px;
	border-right: 1px solid #455070;
}

.close_left_menu .menu_dropdown {
	display: none;
}

.main_menu .top_menu_list:hover .menu_dropdown {
	display: block;
}

.menu_dropdown .upper_sub_menu {
	margin: 0px;
	padding: 0px;
	height: 88%;
	overflow-x: hidden;
	overflow-y: scroll;
	padding-bottom: 30px;
}

.menu_dropdown .upper_sub_menu::-webkit-scrollbar-track {
	-webkit-box-shadow: none;
	border-radius: 10px;
	background-color: transparent;
}

.menu_dropdown .upper_sub_menu::-webkit-scrollbar {
	width: 6px;
	background-color: transparent;
}

.menu_dropdown .upper_sub_menu::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: none;
	background-color: #131827;
}

.menu_dropdown .category_name {
	font-size: 15px;
	text-transform: capitalize;
	padding: 6px;
	margin-bottom: 0px;
	border-bottom: 1px solid #20273a;
	padding-bottom: 12px;
	padding-top: 0px;
	color: #e38524;
	font-weight: 600;
}

.menu_dropdown li {
	position: relative;
}

.menu_dropdown .menu_list {
	display: block;
	padding: 14px;
	width: 100%;
	color: #d0d2d6;
	font-size: 13px;
	position: relative;
	border-radius: 5px;
}

.menu_dropdown .menu_list:hover {
	background: #20273a;
}

.menu_dropdown .menu_list.active {
	background: #20273a;
	border-radius: 8px 8px 0px 0px;
	margin-bottom: 0;
}

.menu_dropdown .menu_list.active .fa {
	color: #eb5e70;
}

.menu_dropdown .menu_list .fa {
	color: #d0d2d6;
	margin-right: 15px;
}

.menu_dropdown .sub_dropdown {
	margin: 0px;
	padding: 0px;
	list-style: none;
	margin-bottom: 3px;
	border-radius: 0px 0px 8px 8px;
	overflow: hidden;
}

.menu_dropdown .sub_dropdown .sub_menu_list {
	display: block;
	padding: 14px;
	width: 100%;
	color: #5c647a;
	font-size: 12px;
	position: relative;
	background: #20273a;
	position: relative;
}

.menu_dropdown .sub_dropdown .sub_menu_list .icon {
	margin-right: 4px;
}

.menu_dropdown .sub_dropdown .sub_menu_list:hover {
	background: #303952;
}

.menu_dropdown .sub_dropdown .sub_menu_list.active {
	color: #e18d24;
}

.top_wrapper.close_left_menu .left_menu .not_display {
	display: none;
}

.top_wrapper.close_left_menu .menu_dropdown .menu_list::after {
	display: none;
}

.top_wrapper.close_left_menu .menu_dropdown .menu_list {
	text-align: center;
}

.top_wrapper.close_left_menu .menu_dropdown .menu_list .fa {
	margin: 0px;
}

.menu_list {
	cursor: pointer;
	margin-bottom: 7px;
}

.menu_list .icon {
	position: absolute;
	right: 3px;
	top: 50%;
	transform: translateY(-50%);
}

.top_wrapper.close_left_menu .menu_dropdown .sub_dropdown.floating_menu {
	position: absolute;
	right: -134px;
	top: 0px;
	display: block;
}

.top_wrapper.close_left_menu .menu_dropdown .sub_dropdown.floating_menu::after {
	content: "\f0d9";
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	position: absolute;
	top: -5px;
	left: -10px;
	font-size: 29px;
	color: #283046;
}

.top_wrapper.close_left_menu .menu_dropdown .sub_dropdown.floating_menu .sub_menu_list {
	padding: 10px;
	min-width: 109px;
}

.custom_footer {
	padding: 6px;
	margin-left: 249px;
	height: 3vh;
}

.custom_inner_footer {
	color: #ffffff;
	display: block;
	width: 100%;
	text-align: center;
}

.top_wrapper.close_left_menu .custom_footer {
	margin-left: 50px;
}

.custom_footer p {
	margin-bottom: 0px;
	font-size: 11px;
}

.section_header {
	color: #fff;
	font-size: 14px;
	text-transform: uppercase;
	margin-bottom: 0px;
	margin-top: 6px;
	font-weight: 500;
}

.section_header small {
	font-size: 12px;
	font-weight: bold;
	color: #e38524;
}

.form_header {
	color: #fff;
	text-transform: capitalize;
	font-size: 15px;
	font-weight: 600;
	padding: 10px;
	border-radius: 5px;
}

.action_button {
	border: none;
	background: transparent;
	color: #6433d8;
	font-size: 17px;
}

.action_button.trash_btn {
	color: #c70c0c;
}

.icon-spin {
	-webkit-animation: icon-spin 2s infinite linear;
	animation: icon-spin 2s infinite linear;
}

.table-responsive::-webkit-scrollbar-track {
	-webkit-box-shadow: none;
	border-radius: 10px;
	background-color: transparent;
}

.table-responsive::-webkit-scrollbar {
	width: 2px;
	background-color: transparent;
}

.table-responsive::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: none;
	background-color: #bbbbbb;
}

.trashBox {
	color: #FFF;
}

/* .custom_form p {
	color: #e38524;
}

.jodit-context p {
	color: #000;
}
.jodit-context li {
	color: #000;
} */

@-webkit-keyframes icon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes icon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

.toaster_message {
	font-size: 1rem;
}

.left_menu_category_level {
	margin-bottom: 5px;
}

.search_box {
	margin-left: 3rem;
}

.custom_table th,
td {
	vertical-align: middle;
}

.btn-grad {
	background-image: linear-gradient(to right, #314755 0%, #26a0da 51%, #314755 100%);
}

.btn-grad {
	background-image: linear-gradient(to right, #2b5876 0%, #4e4376 51%, #2b5876 100%);
}

.btn-grad {
	margin: 10px;
	text-align: center;
	text-transform: none;
	transition: 0.5s;
	background-size: 200% auto;
	color: white;
	border-radius: 10px;
	display: inline-block;
	font-size: 14px !important;
}

.btn-grad:hover {
	background-position: right center;
	/* change the direction of the change here */
	color: #fff;
	text-decoration: none;
	box-shadow: 1px 10px 12px 0px rgba(13, 3, 3, 0.2) !important;
}

hr {
	background-color: #464646 !important;
	z-index: -9;
	opacity: 1 !important;
	height: 2px;
}

.mobile_menu_close {
	display: none;
}

.user_detail_box .user_details_name {
	display: block;
	margin-top: 8px;
	color: #fff;
}

.user_detail_box .user_details {
	display: block;
	margin-top: 8px;
	color: #fff;
	font-size: 15px;
}

.user_detail_box .user_verify {
	margin-left: 5px;
	font-size: 13px;
	font-weight: 700;
}

.user_detail_box .user_verified {
	margin-left: 5px;
	font-size: 9px;
	font-weight: 700;
	background: #25df45;
	padding: 3px 4px;
	border-radius: 50px;
	position: relative;
	top: -2px;
	color: #ffff;
}

.user_detail_box {
	padding-left: 15px;
}

.pagination {
	justify-content: center;
}

.cancel_btn {
	font-size: 14px !important;
}

.blank_box {
	display: table;
	text-align: center;
	width: 100%;
	height: 350px;
}

.blank_box .blank_inner_box {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100px;
	color: #FFF;
}

.custom_nav .logout_admin {
	display: none;
}

.serch-align {
	text-align: right;
}

.custom_table.table-hover>tbody>tr:hover>* {
	color: #fff !important;
}

.custom_table.table-hover tbody tr:hover {
	background: #2a2f45;
}

.btn-link {
	color: #e38524 !important;
}

.ck-icon {
	z-index: auto !important;
}

.blank_inner_box svg path {
	stroke: #eee;
}

.pagination {
	background: transparent;
}

.pagination .page-link {
	background: transparent;
}

.pagination .page-item.active .page-link {
	background: #455070 !important;
	border-color: inherit;
}

.modal .modal-content {
	color: #fff;
}

.modal .modal-body {
	background: #2f3444;
	color: #fff;
}

.modal .modal-header,
.modal .modal-footer {
	background: #2a2f45;
	color: #fff;
}

.error_modal.modal .modal-content {
	text-align: center;
	box-shadow: 0px 0px 10px #000;
}

.error_modal.modal .modal-content .error_occurred {
	color: red;
	font-size: 25px;
	font-weight: 600;
}

.error_modal.modal .modal-body {
	background: #000;
	color: #fff;
}

.form-check .form-check-label {
	color: #fff;
}

.text-white {
	color: #fff;
}

.rdtPicker {
	color: #030303 !important;
}

.select-color {
	color: #000 !important;
}

.all_card_box {
	border-radius: 4px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.all_card_box .box_icon {
	font-size: 40px;
	margin: 0px;
}

.all_card_box .card_inner_text {
	text-align: right;
	margin: 0px;
}

.all_card_box .card_inner_text span {
	color: #000;
	font-size: 20px;
	font-weight: 500;
	display: block;
	margin-bottom: 42x;
}

.all_card_box .card_inner_text span:last-child {
	font-size: 12px;
	color: #6e6e6e;
}

.custom_table tr {
	border-color: #4a4d4e !important;
}

.custom_table thead tr th {
	color: #ea8c0d !important;
}

@media(max-width:1390px) and (min-width:1200px) {
	.custom_table tr {
		height: 50px;
	}

	.custom_nav {
		height: 7vh;
		padding: 4px 0px;
	}

	.top_wrapper .right_section {
		height: 90vh;
	}

	.custom_footer {
		padding: 3px;
		height: 3vh;
	}

	.custom_footer p {
		margin-bottom: 0px;
		font-size: 10px;
	}

	.menu_dropdown .menu_list {
		font-size: 12px;
	}

	.menu_dropdown .sub_dropdown .sub_menu_list {
		font-size: 10px;
	}

	.main_menu {
		padding-top: 6px;
	}

	.custom_nav .top_searchbar {
		width: 460px;
	}
}

@media (max-width: 767px) and (min-width: 200px) {
	.serch-align {
		text-align: center;
	}

	.custom_form .form-label {
		text-align: left;
	}

	.table-responsive {
		overflow: auto !important;
	}

	.custom_form .form-control {
		margin-bottom: 10px;
	}

	.search_box {
		margin-left: 0px;
	}

	.top_wrapper .menu_wrapper {
		position: fixed;
		z-index: 999;
		width: 100%;
		height: 100vh;
		overflow: hidden;
	}

	.top_wrapper.close_left_menu .menu_wrapper {
		width: 0px;
		overflow: hidden;
	}

	.menu_wrapper .elm_logo_mobile {
		width: 164px;
		position: relative;
		left: 58px;
		top: 5px;
		display: block;
	}

	.menu_dropdown {
		width: 88vw;
		padding-bottom: 20px;
		margin-left: 0px;
		left: 50px;
	}

	.custom_nav {
		margin: 0px;
	}

	.custom_nav .elm_logo {
		display: none !important;
	}

	.top_wrapper.close_left_menu .custom_nav {
		margin-left: 0px;
		padding: 7px 0px;
		position: fixed;
		width: 100%;
		z-index: 99;
		background: #293e5b;
		height: 7vh;
	}

	.custom_nav .top_searchbar {
		width: 78%;
		float: left;
	}

	.top_wrapper .right_section {
		position: relative;
		margin: 0px;
		height: 90vh;
		border-radius: 3px 3px 0px 0px;
		margin-top: 7vh;
	}

	.top_wrapper.close_left_menu .right_section {
		margin-left: 0px;
	}

	.custom_footer {
		margin-left: 0px;
	}

	.top_wrapper.close_left_menu .custom_footer {
		margin-left: 0px;
		padding: 5px;
	}

	.custom_footer p {
		margin-bottom: 0px;
		font-size: 9px;
	}

	.mobile_menu_close {
		display: block;
		position: absolute;
		z-index: 999;
		right: 12px;
		font-size: 21px;
		top: 9px;
		color: #dcdcdc !important;
		font-weight: 100;
		padding: 10px;
	}

	.user_detail_box .user_details {
		margin-top: -14px;
		color: #000;
	}

	.user_info_header .user_menu #basic-nav-dropdown {
		width: 28px;
		height: 28px;
		line-height: 23px;
		font-size: 13px;
	}

	.menu_dropdown .upper_sub_menu {
		padding-bottom: 12px;
	}

	.user_info_header {
		display: none;
	}

	.custom_nav .close_left_menu {
		display: inline-block;
	}

	.custom_nav .logout_admin {
		width: 7%;
		padding: 0px;
		border: none;
		background: transparent;
		color: #fff;
		margin-left: 10px;
		position: relative;
		top: 8px;
		font-size: 14px;
		display: inline-block;
	}
}

.custom_table {
	color: #fff !important;
}

.default_cancel_btn {
	font-size: 14px !important;
	border: 1px solid #eee !important;
	color: #fff !important;
}

.ck-editor__editable {
	min-height: 320px;
	height: 320px;
	overflow-y: scroll;
}

.not_readonly_control {
	background-color: #fff !important;
}

/*---------------------- Invoice Section ------------------------*/
.invoice {
	color: #a4a4a4;
}

.invoice_title {
	color: #6bc5a4;
	font-size: 40px;
	text-transform: uppercase;
	margin-top: 30px;
}

.invoice_address {
	background: #f7f7f9;
	padding: 20px;
	margin-top: 10px;
	margin-left: -15px;
	margin-right: -15px;
}

.inv_to {
	text-transform: uppercase;
	font-size: 14px;
}

.corporate_id {
	font-weight: bold;
	font-size: 16px;
	color: #2a323f;
	margin-top: 5px;
	text-transform: uppercase;
}

.inv_label {
	font-style: italic;
	font-size: 22px;
	color: #6bc5a4;
}

.blank_modal_box {
	display: table;
	text-align: center;
	width: 100%;
}

.blank_modal_box .blank_modal_inner_box {
	display: flex;
	justify-content: center;
	align-items: center;
}