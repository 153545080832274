body {
	background: #f6f6f6;
	/* color: #909193 !important; */
	font-size: 14px;
	font-family: "Poppins", sans-serif !important;
}
a {
	text-decoration: none;
}
.login_full_wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	display: table;
	overflow: hidden;
	background: #f6f6f6 !important;
}
.background_overley {
	position: absolute;
	bottom: 0px;
	right: 0px;
	z-index: -9;
}
.login_top_box {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
}
.login_inner_box {
	width: 880px;
	margin: auto;
	display: block;
	background: #fff;
	padding: 20px;
	min-height: 300px;
	border-radius: 8px;
	box-shadow: 0px 0px 26px rgb(0 0 0 / 10%);
	position: relative;
}
.login_inner_box .box_shadow {
	position: absolute;
	bottom: -128px;
	width: 70%;
	margin: auto;
	left: 0px;
	right: 0px;
	opacity: 0.2;
}
.elm_logo {
	width: 150px;
}
.welcome_back {
	font-size: 18px;
	font-weight: 700;
	color: #283046;
	text-transform: capitalize;
	margin-top: 60px;
	margin-bottom: 0px;
}
.custom_login_form .input-group {
	position: relative;
	margin-bottom: 15px;
}
.custom_login_form .input-group-text {
	background: transparent;
	border: none;
	position: absolute;
	z-index: 99;
	top: 8px;
	color: #6433d8;
}
.custom_login_form .form-control {
	padding-left: 36px;
	background: transparent;
	border-radius: 4px !important;
	height: 40px;
	transition: all 0.3s;
	font-size: 0.9rem;
}
.custom_login_form .form-control:focus {
	border-color: #6433d8;
	-webkit-box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
	box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
	padding-left: 40px;
	transition: all 0.3s;
}
.admin_btn {
	border: none;
	background: #6433d8;
	color: #fff;
	padding: 8px 22px;
	font-size: 15px;
	border-radius: 5px;
	text-transform: uppercase;
}
.otp_input {
	font-size: 14px !important;
	text-align: center;
	padding-left: 6px !important;
}
.otp_input:focus {
	border-color: #7367f0;
	-webkit-box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
	box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}
@media (max-width:768px) {
	.welcome_back{
		margin-top: 0px;
	}
	.login_full_wrapper {
		position: relative;
		height: 100vh;
	}
	.background_overley{
		z-index: auto;
	}
	.login_inner_box {
		width: 89%;
		margin-top: 30px;
		margin-bottom: 30px;
	}
}
@media(max-width: 1080px) and (min-width:768px) {
	.login_inner_box {
		width: 60%;
	}
}